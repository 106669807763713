<!-- (c) 才酝通 -->
<template>
  <div>
    <!-- 顶部返回操作 弹出框内的选择信息-->
    <van-popup v-model="showTreeSelect" position="right" :style="{ height: '100%',width:'100%' }">
      <div class="navHeader">
        <img
            class="back"
            src="../../../assets/img/back-b.png"
            @click="showTreeSelect=false"
        />
        <span class="title">请选择科目</span>
      </div>
      <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :active-color=true
        style="margin-top: 60px"
        @click-item="getChildInfo"
        @click-nav="getParentInfo"
      />
    </van-popup>
    <!--选择科目-->
    <div class="changeTitle" @click="getCategoryList">
      <span class="title">{{ selectivePName }} {{selectiveCName ? '- ' +selectiveCName : ""}}</span>
      <img class="change-icon" src="../../../assets/img/svg/change.svg"/>
    </div>
    <!--操作类型-->
    <div class="content">
      <div class="second-box">
        <div class="model">
          <div class="small-model exam" @click="goExam()">
            <!-- <van-icon name="feedback" class="icon" color="#FFF" size="28" /> -->
            <img class="exam_icon" src="../../../assets/img/new/exam.png" alt="">
            <div class="title"><span>真</span>题考试</div>
            <div class="title title_y">EXAM</div>
          </div>
          <div class="small-model mock" @click="goMock()">
            <!-- <van-icon name="edit" class="icon" color="#FFF" size="28" /> -->
            <img src="../../../assets/img/new/mock.png" alt="">
            <div class="title"><span>模</span>拟考试</div>
            <div class="title title_y">MOCK</div>
          </div>
        </div>
      </div>
      <div class="second-box">
        <div class="model">
          <div class="small-model practice" @click="goPractice()">
            <!-- <van-icon name="menu" class="icon" color="#FFF" size="28" /> -->
            <img src="../../../assets/img/new/practice.png" alt="">
            <div class="title"><span>练</span>习模式</div>
            <div class="title title_y">PRACTICE</div>
          </div>
          <div class="small-model wrongbook" @click="goWrong()">
            <!-- <van-icon name="comment" class="icon" color="#FFF" size="28" /> -->
            <img src="../../../assets/img/new/wrong.png" alt="">
            <div class="title"><span>错</span>题本</div>
            <div class="title title_y">WRONG</div>
          </div>
        </div>
      </div>
      <div class="second-box">
        <div class="model">
          <div class="small-model collect" @click="goCollect()">
            <!-- <van-icon name="first-aid" class="icon" color="#FFF" size="28" /> -->
            <img src="../../../assets/img/new/star.png" alt="">
            <div class="title"><span>收</span>藏集</div>
            <div class="title title_y">COLLECT</div>
          </div>
          <div class="small-model search" @click="goSearch()">
            <!-- <van-icon name="search" class="icon" color="#FFF" size="28" /> -->
            <img src="../../../assets/img/new/search.png" alt="">
            <div class="title"><span>搜</span>索题目</div>
            <div class="title title_y">SEARCH</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {Icon} from 'vant-green';

Vue.use(Icon);
import {mapState} from "vuex";
import NavHeader from "@/components/nav-header.vue";

export default {
  computed: {
    ...mapState(["isLogin"]),
  },
  mounted() {
    // this.getCategoryList()
  },
  data() {
    return {
      showTreeSelect: false,
      selectivePName: '请选择科目',
      selectiveCName: '',
      items: [],
      activeId: 1,
      activeIndex: 0,
      parentIndex: 0,
      bottomPopupId1:null,
      bottomPopupId2:null,
    };
  },
  methods: {
    goExam() {
      if (!this.isLogin) {
        this.goLogin();
      } else {
        if (this.bottomPopupId1 === null || this.bottomPopupId2 === null ){
          this.getCategoryList()
          return
        }
        this.$router.push({
          name: 'AnswerExams',query:{"cid1":this.bottomPopupId1, "cid2":this.bottomPopupId2,"selectivePName":this.selectivePName, "selectiveCName":this.selectiveCName}
        })
      }
    },
    goMock() {
      if (!this.isLogin) {
        this.goLogin();
      } else {
        if (this.bottomPopupId1 === null || this.bottomPopupId2 === null ){
          this.getCategoryList()
          return
        }
        this.$router.push({
          name: 'AnswerMocks',query:{"cid1":this.bottomPopupId1, "cid2":this.bottomPopupId2,"selectivePName":this.selectivePName, "selectiveCName":this.selectiveCName}
        })
      }
    },
    goPractice() {
      if (!this.isLogin) {
        this.goLogin();
      } else {
        if (this.bottomPopupId1 === null || this.bottomPopupId2 === null ){
          this.getCategoryList()
          return
        }
        this.$router.push({
          name: 'AnswerLearns',query:{"cid1":this.bottomPopupId1, "cid2":this.bottomPopupId2,"selectivePName":this.selectivePName, "selectiveCName":this.selectiveCName}
        })
      }
    },
    goWrong() {
      if (!this.isLogin) {
        this.goLogin();
      } else {
        this.$router.push({
          name: 'AnswerErrors',
        })
      }
    },
    goCollect() {
      if (!this.isLogin) {
        this.goLogin();
      } else {
        this.$router.push({
          name: 'AnswerCollects',
        })
      }
    },
    goSearch() {
      if (!this.isLogin) {
        this.goLogin();
      } else {
        this.$router.push({
          name: 'AnswerSearch',
        })
      }
    },
    goLogin() {
      this.$router.push({
        name: "Login",
        query: {
          url: window.location.href,
        },
      });
    },
    getCategoryList() {
      if (!this.isLogin) {
        this.goLogin();
      }
      this.showTreeSelect=true
      console.log(this.$api)
      this.$api.Home.GetCategoryListInfo().then(res => {
        this.items = res.data;
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].children.unshift({
            "id": 0,
            "text": "全部",
          },)
        }
        // console.log("res.data",res.data)
      })
    },
    getParentInfo(parentInfo){
      // console.log("父标签的索引: ",parentInfo)
      this.parentIndex = parentInfo
    },
    getChildInfo(childInfo){
      this.bottomPopupId2 = childInfo.id
      this.bottomPopupId1 = this.items[this.parentIndex].id
      // 设置显示的 选择的 科目
      this.selectivePName =  this.items[this.parentIndex].text
      this.selectiveCName =  childInfo.text
      this.showTreeSelect=false
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 10px;

  .second-box {
    width: 100%;
    margin: 0 auto;
    height: 140px;
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    box-sizing: border-box;
    padding: 0 20px;

    .tit {
      width: 100%;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-bottom: 27px;
    }

    .model {
      width: 100%;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;

      .normal-model {
        width: 100%;
        height: 82px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        padding: 0px 30px;
        box-sizing: border-box;
        background: linear-gradient(90deg, #ff4c72 0%, #ff9996 100%);

        .title {
          margin-top: 26px;
          width: 100%;
          height: 33px;
          font-size: 16px;;
          font-weight: 600;
          color: #ffffff;
          line-height: 33px;
          letter-spacing: 1px;
        }

        .info {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          margin-top: 20px;
        }

        .icon {
          width: 50px;
          height: 50px;
          margin-top: 20px;
        }

        &:hover {
          box-shadow: 0px 4px 8px 0px rgba(102, 102, 102, 0.1);
        }
      }

      .small-model {
        width: 50%;
        height: 130px;
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        padding: 20px 20px 0 20px;

        .title {
          width: 100%;
          font-size: 15px;;
          color: #ffffff;
          letter-spacing: 1px;

          span {
            font-size: 24px;
            font-weight: 600;
          }
        }

        .title_y {
          opacity: 0.7;
        }

        .info {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          margin-top: 20px;
        }

        img {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 20px;
          bottom: 20px;
        }

        .exam_icon {
          width: 32px;
          height: 32px;
        }

        &:hover {
          box-shadow: 0px 4px 8px 0px rgba(102, 102, 102, 0.1);
        }
      }

      .exam {
        background: linear-gradient(270deg, #34bfff 0%, #1784ed 100%);
        margin-right: 10px;
      }

      .mock {
        background: linear-gradient(270deg, #03e4d9 0%, #00baa6 100%);
        //margin-left: 10px;
      }

      .practice {
        background: linear-gradient(270deg, #ffbf47 0%, #ed8917 100%);
        margin-right: 10px;
      }

      .wrongbook {
        background: linear-gradient(270deg, #ff8383 0%, #ff4040 100%);
        //margin-left: 10px;
      }

      .collect {
        background: linear-gradient(270deg, #c48df1 0%, #9254de 100%);
        margin-right: 10px;
      }

      .search {
        background: linear-gradient(270deg, #9de736 0%, #8bc737 100%);
        //margin-left: 10px;
      }
    }
  }
}

// 顶部返回 按钮 和标题
.navHeader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  z-index: 100;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f1f2f6;

  img {
    position: absolute;
    left: 15px;
    width: 19px;
    height: 19px;
  }

  .title {
    width: 72%;
    height: auto;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: #333333;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.changeTitle {
  margin-top: 10px;
  width: 100%;
  background: #fff;
  padding: 20px 25px 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    line-height: 18px;
  }

  .change-icon {
    width: 18px;
  }
}
</style>
