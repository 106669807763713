<!-- (c) 才酝通 -->
<template>
    <div class="container">
      <!-- <index-exam></index-exam> -->
        <van-notice-bar left-icon="volume" scrollable background="#e1f0f9" color="#4381E3" mode="link" @click="goNotice(notice.id)" v-if="!loading3">
          {{ notice.title }}
        </van-notice-bar>
        <div class="box">
          <template v-if="loading && sliders.length == 0">
            <skeletonSlider></skeletonSlider>
          </template>
          <index-slider :items="sliders" v-else></index-slider>
          <index-exam></index-exam>
          <!-- <index-topic :items="hotList"></index-topic> -->
<!--          <template v-if="config">-->
<!--            <div class="power">Copyright 2024 {{config.web_name}} </div>-->
<!--            <div class="support">{{config.web_name}}提供技术支持</div>-->
<!--          </template>-->
        </div>
        <nav-footer type="index"></nav-footer>
    </div>
</template>

<script>
  import { mapState } from "vuex";
  import Vue from 'vue';
  import { NoticeBar } from 'vant-green';

  Vue.use(NoticeBar);
  import IndexSlider from "./components/slider.vue";
  // import IndexTopic from "./components/topic.vue";
  import IndexExam from "./components/exam.vue";
  import NavFooter from "../../components/footer.vue";
  import skeletonSlider from '../../components/skeleton/skeletonSlider';

  export default {
    computed: {
      ...mapState(["config"]),
    },
    components: {
      IndexSlider,
      // IndexTopic,
      IndexExam,
      NavFooter,
      skeletonSlider
    },
    data() {
      return {
        loading: false,
        sliders: [],
        hotList: [],
        keywords: null,
        notice: null,
        loading3: true
      }
    },
    beforeRouteEnter(to, from, next) {
        if (from.name != null) {
            console.log('使用缓存')
            to.meta.useCache = true;
        }else {
            to.meta.useCache = false;
        }
        next()
    },
    activated() {
      if(!this.$route.meta.useCache){
        this.getSliders();
        this.getHotData();
        this.getNotice();
      }else {
        if (!this.sliders.length || !this.hotList.length || !this.notice == null) {
          this.getSliders();
          this.getHotData();
          this.getNotice();
        }
      }
      console.log('activated')
    },
    mounted() {
        // this.getSliders();
        // this.getHotData();
        // this.getNotice();
    },
    methods: {
      getSliders() {
          this.loading = true;
          this.$api.Sliders.Index({ platform: "H5" }).then((res) => {
              this.sliders = res.data;
              this.loading = false;
          });
      },
      getHotData() {
          this.loading2 = true;
          this.$api.Topic.HotList().then((res) => {
            this.loading2 = false;
            this.hotList = res.data;
          });
      },
      getNotice() {
          this.loading3 = true;
          this.$api.Home.Announcement().then((res) => {
            this.loading3 = false;
            this.notice = res.data;
          });
      },
      goNotice(id) {
        this.$router.push({
            name: "Announcement",
            query: {
                id: id
            },
        });
      }
    }
  }
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #f3f6f9;
  .navTab {
    width: 100%;
    height: 50px;
    background: #ffffff;
    position: relative;
    display: flex;
    padding: 8px 15px;
    .search-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 17px;
      left: 37px;
      cursor: pointer;
    }
    .input {
      width: 345px;
      height: 34px;
      background: #f5f5f5;
      border-radius: 17px;
      outline: none;
      border: none;
      padding-left: 50px;
      font-size: 14px;
    }
  }

  .blocks-box {
    width: 100%;
    height: 173px;
    background: #ffffff;
    padding: 10px 25px 20px 25px;
    display: grid;
    row-gap: 15px;
    column-gap: 50px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    .item {
      width: 44px;
      height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item-icon {
        width: 44px;
        height: 44px;
        margin-bottom: 9px;
      }
      span {
        width: 44px;
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 12px;
        text-align: center;
      }
    }
  }
  .box {
    width: 100%;
    margin-bottom: 60px;
    .power {
      width: 100%;
      height: auto;
      float: left;
      padding-top: 40px;
      padding-bottom: 10px;
      text-align: center;
      color: #999;
      font-size: 16px;
    }
    .support {
      width: 100%;
      height: auto;
      float: left;
      padding-top: 0px;
      padding-bottom: 90px;
      text-align: center;
      color: #999;
      font-size: 14px;
    }
  }
}
</style>
